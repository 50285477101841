import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './gen.css';

const Generate = ({userInfo}) => {
  const [version, setVersion] = useState('live');
  const [days, setDays] = useState(365);
  const [reason, setReason] = useState('buy');
  const [customDays, setCustomDays] = useState('');
  const [result, setResult] = useState(null);

  const handleVersionChange = (e) => setVersion(e.target.value);
  const handleDaysChange = (e) => setDays(e.target.value);
  const handleReasonChange = (e) => setReason(e.target.value);
  const handleCustomDaysChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setCustomDays(value);
    }
  };

  const handleGenerate = async () => {
    const daysToSend = days === 'other' ? customDays : days;
    try {
      const response = await axios.get(
        'https://director.ankumo.ru/web/api/key/generate',
        { params: { build: version, days: daysToSend, reason: reason, user: userInfo.username } },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }
        }
      );
      setResult(response.data);
      toast.success('Key generated')
    } catch (error) {
      setResult(null);
      toast.error(error.response ? error.response.data.message : 'Error connecting to server')
    }
  };

  return (
    <div className="generate-container">
      <form className="generate-form" onSubmit={(e) => e.preventDefault()}>
      <label>
          <select value={reason} onChange={handleReasonChange}>
            <option value="buy">Buy</option>
            <option value="media">Media Deal</option>
            <option value="roulette">Roulette</option>
          </select>
        </label>
        <label>
          <select value={version} onChange={handleVersionChange}>
            <option value="live">Live</option>
            <option value="debug">Debug</option>
          </select>
        </label>
        <label>
          <select value={days} onChange={handleDaysChange}>
            <option value={365}>365</option>
            <option value={1}>1</option>
            <option value={3}>3</option>
            <option value={7}>7</option>
            <option value={30}>30</option>
            <option value="other">Другое</option>
          </select>
        </label>
        {days === 'other' && (
          <input
            type="number"
            value={customDays}
            onChange={handleCustomDaysChange}
            placeholder="Количество дней"
            className="custom-days-input"
          />
        )}
        <button onClick={handleGenerate} className="generate-button">Generate</button>
      </form>
      {result && (
        <div className="generate-result">
          <pre>{result.message}</pre>
        </div>
      )}
    </div>
  );
};

export default Generate;
