import React, { useEffect } from 'react';
import './canvas.css';

const CanvasComponent = () => {
    useEffect(() => {
        const scriptGSAP = document.createElement('script');
        scriptGSAP.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.9.1/gsap.min.js';
        scriptGSAP.async = true;
        document.body.appendChild(scriptGSAP);

        scriptGSAP.onload = () => {
            const script = document.createElement('script');
            script.src = `${process.env.PUBLIC_URL}/canvas.js`;
            script.async = true;
            document.body.appendChild(script);
        };

        return () => {
            document.body.removeChild(scriptGSAP);
            const existingScript = document.querySelector(`script[src="${process.env.PUBLIC_URL}/canvas.js"]`);
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
        };
    }, []);

    return (
        <div className="canvas-container">
            <canvas id="demo-canvas"></canvas>
        </div>
    );
};

export default CanvasComponent;
