import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jsonwebtoken';

const DiscordCallback = ({ setUserInfo }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {

          const response = await axios.post('https://director.ankumo.ru/web/auth', { code }, {
            headers: {
              'Content-Type': 'application/json',
            }
          })

          const { token } = response.data;
          localStorage.setItem('jwtToken', token);
          const decoded = jwt.decode(token);
          setUserInfo(decoded);
          navigate('/');

        }
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();
  }, []);

  return <div>SERVER DED</div>;
};

export default DiscordCallback;
