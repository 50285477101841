import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopBar from './Topbar/TopBar';
import Home from './Home/Home';
import DiscordCallback from './Login/DiscordCallback';
import Search from './Search/Search';
import Generate from './Generation/Gen';
import Keys from './Keys/Keys';
import CanvasComponent from './Canvas/CanvasElement';
import PrivateRoute from './PrivateRoute';
import Hwid from './Hwid/Hwid';
import Ban from './Ban/Ban';
import './styles.css';
import jwt from 'jsonwebtoken';

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fileCount, setFileCount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      const decoded = jwt.decode(token);
      setUserInfo(decoded);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setUserInfo(null);
    setIsAuthenticated(false);
  };

  const handleSetUserInfo = (decoded) => {
    setUserInfo(decoded);
    setIsAuthenticated(true);
  };

  const fetchFileCount = async () => {
    try {
      const response = await axios.get('https://director.ankumo.ru/web/api/hwid/get', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setFileCount(response.data.length);
    } catch (error) {
      setFileCount(0);
    }
  };

  useEffect(() => {
    fetchFileCount();
  }, []);

  return (
    <Router>
      <div id="main">
        <ToastContainer />
        <TopBar isAuthenticated={isAuthenticated} handleLogout={handleLogout} userInfo={userInfo} fileCount={fileCount} />
        <Routes>
          <Route path="/callback" element={<DiscordCallback setUserInfo={handleSetUserInfo} />} />
          <Route path="/" element={<Home userInfo={userInfo} />} />
          <Route path="/find" element={<PrivateRoute element={Search} isAuthenticated={!!userInfo} userInfo={userInfo} />} />
          <Route path="/generate" element={<PrivateRoute element={Generate} isAuthenticated={!!userInfo} userInfo={userInfo} />} />
          <Route path="/keys" element={<PrivateRoute element={Keys} isAuthenticated={!!userInfo} userInfo={userInfo} />} />
          <Route path="/hwidreset" element={<PrivateRoute element={Hwid} isAuthenticated={!!userInfo} userInfo={userInfo} setFileCount={setFileCount} />} />
          <Route path="/ban" element={<PrivateRoute element={Ban} isAuthenticated={!!userInfo} userInfo={userInfo} />} />
        </Routes>
        <CanvasComponent />
      </div>
    </Router>
  );
};

export default App;
