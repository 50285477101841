import React from 'react';
import './home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1><span className="white">angelwings<span/></span><span className="pink">.pink</span></h1>
    </div>
  );
};

export default Home;