import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BanContainer from './BanContainer';
import './ban.css';
import { toast } from 'react-toastify';

const Ban = ({ userInfo }) => {
  const [keys, setKeys] = useState([]);
  const isAdmin = userInfo.role === "Admin";

  const fetchKeys = async () => {
    try {

      const response = await axios.get('https://director.ankumo.ru/web/api/keys/get', {
        params: { variable: "ban", user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setKeys(response.data);

    } catch (error) {

      setKeys([]);
      toast.error(error.response ? error.response.data.message : 'Error connecting to server');

    }
  };

  useEffect(() => {
    fetchKeys();
  }, []);

  const handleDeleteKey = async (keyId) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/unban`, {
        params: { key: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success('File deleted successfully');
      fetchKeys();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error deleting key');
    }
  };

  return (
    <div className="keys-page">
      <div className="keys-container">
        {keys.map((keyData) => (
          <BanContainer
            key={keyData._id}
            keyData={keyData}
            onDelete={handleDeleteKey}
            isAdmin={isAdmin}
          />
        ))}
        <span className="focus-bg"></span>
      </div>
    </div>
  );
}

export default Ban;
