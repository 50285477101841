import React from 'react';
import './hwid.css';

const HwidContainer = ({hwiddata, onApprove, onDecline}) => {

  return (
    <div className="hwid-container">
      <div className="hwid-details">
        <p><strong>Username:</strong> {hwiddata.username}</p>
        <p><strong>Key:</strong> {hwiddata.key}</p>
        <p><strong>New HWID:</strong> {hwiddata.newhwid}</p>
        <p><strong>HWID:</strong> {hwiddata.hwid}</p>
        <p><strong>IP:</strong> {hwiddata.ip}</p>
        <p><strong>Reg IP:</strong> {hwiddata.old_ip}</p>
      </div>
      <div className="hwid-buttons">
        <button className="approve-button" onClick={() => onApprove(hwiddata.key, hwiddata.newhwid)}>Approve</button>
        <button className="decline-button" onClick={() => onDecline(hwiddata.key)}>Decline</button>
      </div>
    </div>
  );
};

export default HwidContainer;
