import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faExchangeAlt, faChevronDown, faChevronUp, faClock, faBan } from '@fortawesome/free-solid-svg-icons';
import './keys.css';

function timeConverter(UNIX_timestamp) {
  let a = new Date(UNIX_timestamp * 1000);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}

const KeyContainer = ({ keyData, onDelete, onSwitchBuild, onExpand, onBan, isAdmin, selectedParam }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [days, setDays] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDelete = () => {
    onDelete(keyData.key, keyData.hwid);
    setMenuOpen(false);
  };

  const handleSwitchBuild = () => {
    const newBuild = keyData.build === 'debug' ? 'live' : 'debug';
    onSwitchBuild(keyData.key, newBuild);
    setMenuOpen(false);
  };

  const handleExpand = () => {
    setModalOpen(true);
    setMenuOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setDays('');
  };

  const handleSubmit = () => {
    onExpand(keyData.key, days);
    handleCloseModal();
  };

  const handleBan = () => {
    onBan(keyData.key);
    setMenuOpen(false);
  };

  return (
    <div className="key-container">
      <div className="settings-button" onClick={toggleMenu}>
        <FontAwesomeIcon icon={menuOpen ? faChevronUp : faChevronDown} />
      </div>
      <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
        {isAdmin && selectedParam !== 'ban' && <button className="dropdown-item" onClick={handleBan}>
          <FontAwesomeIcon icon={faBan} /> Ban
        </button> }
        {isAdmin && <button className="dropdown-item" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashAlt} /> Delete
        </button> }
        {selectedParam !== 'ban' && <button className="dropdown-item" onClick={handleSwitchBuild}>
          <FontAwesomeIcon icon={faExchangeAlt} /> Switch Build
        </button>}
        {selectedParam !== 'ban' && <button className="dropdown-item" onClick={handleExpand}>
          <FontAwesomeIcon icon={faClock} /> Expand
        </button>}
      </div>
      <div className="key-details">
        {keyData.username && <p><strong>Username:</strong> {keyData.username}</p>}
        {keyData.hwid && <p><strong>Hwid:</strong> {keyData.hwid}</p>}
        {keyData.reg_ip && <p><strong>IP:</strong> {keyData.reg_ip}</p>}
        {keyData.ip && <p><strong>IP:</strong> {keyData.ip}</p>}
        {keyData.key && <p><strong>Key:</strong> {keyData.key}</p>}
        {keyData.build && <p><strong>Build:</strong> {keyData.build}</p>}
        {keyData.sub_time && <p><strong>Expires:</strong> {timeConverter(keyData.sub_time)}</p>}
        {keyData.steam && keyData.steam.length > 0 && (
          <div>
            <p><strong>Steam:</strong> {keyData.steam.map((steamId, index) => (
              <React.Fragment key={index}>
                <a href={`https://steamcommunity.com/profiles/${steamId}`} target="_blank" rel="noopener noreferrer" className='steam-link'>
                  {steamId}
                </a>
                {index < keyData.steam.length - 1 && ', '}
              </React.Fragment>
            ))}</p>
          </div>
        )}
        {keyData.discord && <p><strong>Discord:</strong> {keyData.discord}</p>}
        {keyData.discord_auth && <p><strong>Discord:</strong> {keyData.discord_auth}</p>}
        {keyData.created_at && <p><strong>Created At:</strong> {timeConverter(keyData.created_at)}</p>}
        {keyData.referrer && <p><strong>Referrer:</strong> {keyData.referrer}</p>}
        {keyData.reason && <p><strong>Reason:</strong> {keyData.reason}</p>}
      </div>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Expand Subscription</h2>
            <input
              type="number"
              placeholder="Enter number of days"
              value={days}
              onChange={(e) => setDays(e.target.value)}
            />
            <div className="modal-buttons">
              <button className="modal-button" onClick={handleSubmit}>Submit</button>
              <button className="modal-button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KeyContainer;