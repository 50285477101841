import React from 'react';
import { Link } from 'react-router-dom';
import './topbar.css';
import Status from '../Status/Status';

const TopBar = ({ isAuthenticated, handleLogout, userInfo, fileCount }) => {
  return (
    <div className="top-bar">
      <Status />
      {!isAuthenticated ? (
        <a href={`https://discord.com/api/oauth2/authorize?client_id=768612616637513738&redirect_uri=${encodeURIComponent('https://angelwngs.tech/callback')}&response_type=code&scope=identify`}>
          <button>Login</button>
        </a>
      ) : (
        <>
          <Link to="/"> {userInfo && (
            <div className="user-info">
              <img src={`https://cdn.discordapp.com/avatars/${userInfo.id}/${userInfo.avatar}.png`} alt="avatar" className="avatar" />
              <span className="username">{userInfo.username} ({userInfo.role})</span>
            </div>
          )} </Link>
          <Link to="/find" className="top-bar-link">Find</Link>
          <Link to="/keys" className="top-bar-link">Database</Link>
          <Link to="/hwidreset" className="top-bar-link">
            HWID
            {fileCount > 0 && <span className="notification">{fileCount}</span>}
          </Link>
          <Link to="/ban" className='top-bar-link'>Ban</Link>
          <Link to="/generate" className="top-bar-link">Generate</Link>
          <button onClick={handleLogout}>Logout</button>
        </>
      )}
    </div>
  );
};

export default TopBar;
