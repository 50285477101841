import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faBan } from '@fortawesome/free-solid-svg-icons';
import './ban.css';

const BanContainer = ({ keyData , onDelete }) => {

  const handleDelete = () => {
    onDelete(keyData._id);
  };

  return (
    <div className="key-container">
        <div className="settings-button" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      <div className="key-details">
        {keyData.hwid && <p><strong>Hwid:</strong> {keyData.hwid}</p>}
        {keyData.ip && <p><strong>IP:</strong> {keyData.ip}</p>}
        {keyData.key && <p><strong>Key:</strong> {keyData.key}</p>}
      </div>
    </div>
  );
};

export default BanContainer;