import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HwidContainer from './HwidContainer';
import './hwid.css';
import { toast } from 'react-toastify';

const Hwid = ({ userInfo, setFileCount  }) => {
    const [hwids, setHwids] = useState([]);

    const fetchHwidResets = async () => {
      try {
        const response = await axios.get('https://director.ankumo.ru/web/api/hwid/get', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setHwids(response.data);
        setFileCount(response.data.length);
      } catch (error) {
        setHwids([]);
        setFileCount(0);
        toast.error(error.response ? error.response.data.message : 'Error connecting to server');
      }
    };
  
    useEffect(() => {
      fetchHwidResets();
    }, []);

    const handleApprove = async (key, newhwid) => {
        try {
            await axios.get('https://director.ankumo.ru/web/api/hwid/approve', {
              params: { key: key, newhwid: newhwid, user: userInfo.username },
              headers: {
                'Content-Type': 'application/json',
              }
            });
            toast.success('HWID change approved');
            fetchHwidResets();
          } catch (error) {
            toast.error(error.response ? error.response.data.message : 'Error approving HWID');
          }
      };
    
      const handleDecline = async (key) => {
        try {
            await axios.get('https://director.ankumo.ru/web/api/hwid/decline', {
              params: { key: key, user: userInfo.username },
              headers: {
                'Content-Type': 'application/json',
              }
            });
            toast.success('HWID change declined');
            fetchHwidResets();
          } catch (error) {
            toast.error(error.response ? error.response.data.message : 'Error declining HWID');
          }
      };
  
  return (
    <div className="hwid-page">
      <div className="hwids-container">
        {hwids.map((hwiddata) => (
          <HwidContainer
            key={hwiddata.key}
            hwiddata={hwiddata}
            onApprove={handleApprove}
            onDecline={handleDecline}
          />
        ))}
        <span className="focus-bg"></span>
      </div>
    </div>
  );
};

export default Hwid;
