import React, { useState } from 'react';
import axios from 'axios';
import JsonContainer from './JsonContainer';
import { toast } from 'react-toastify';
import './search.css';

const Search = ({userInfo}) => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(null);
  const isAdmin =  userInfo.role === 'Admin';

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        'https://director.ankumo.ru/web/api/search',
        { params: { search: query, user: userInfo.username } },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }
        }
      );
      setResult(response.data);
      toast.success('File found')
    } catch (error) {
      setResult(null);
      toast.error(error.response ? error.response.data.message : 'Error connecting to server');
    }
  };

  const handleDeleteKey = async (keyId) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/delete`, {
        params: { key: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success('File deleted successfully')
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error deleting key');
    }
  };

  const handleSwitchBuild = async (keyId, newBuild) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/switch`, {
        params: { build: newBuild, key: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success(`Switched build to ${newBuild}`)
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error switching build');
    }
  };

  const handleExpandSubscription = async (keyId, days) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/expand`, {
        params: { days, key: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success(`Expanded subtime for ${days}`)
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error expanding subscription');
    }
  };

  const handleBan = async (keyId) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/ban`, {
        params: { toBan: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success(`Banned ${keyId}`);
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error');
    }
  };

  return (
    <div className="search-container">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Login / HWID / Key / Steam64"
        className="search-input"
      />
      <button onClick={handleSearch} className="search-button">Search</button>
      {result && (
        <div className="search-result">
          <JsonContainer
            key={result.key}
            keyData={result}
            onDelete={handleDeleteKey}
            onSwitchBuild={handleSwitchBuild}
            onExpand={handleExpandSubscription}
            onBan={handleBan}
            isAdmin={isAdmin}
          />
        </div>
      )}
    </div>
  );
};

export default Search;