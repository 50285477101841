import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import KeyContainer from './KeyContainer';
import './keys.css';
import { toast } from 'react-toastify';

const Keys = ({ userInfo }) => {
  const [keys, setKeys] = useState([]);
  const [selectedParam, setSelectedParam] = useState('unused');
  const isAdmin = userInfo.role === "Admin"

  const fetchKeys = useCallback(async () => {
    try {
      const response = await axios.get('https://director.ankumo.ru/web/api/keys/get', {
        params: { variable: selectedParam, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setKeys(response.data);
    } catch (error) {
      setKeys([]);
      toast.error(error.response ? error.response.data.message : 'Error connecting to server');
    }
  }, [selectedParam]);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  const handleDeleteKey = async (keyId, hwid) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/delete`, {
        params: { key: keyId, user: userInfo.username, param: selectedParam, hwid: hwid },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success('File deleted successfully');
      fetchKeys();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error deleting key');
    }
  };

  const handleSwitchBuild = async (keyId, newBuild) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/switch`, {
        params: { build: newBuild, key: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success(`Switched build to ${newBuild}`);
      fetchKeys();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error switching build');
    }
  };

  const handleExpandSubscription = async (keyId, days) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/keys/expand`, {
        params: { days, key: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success(`Expanded subtime for ${days}`);
      fetchKeys();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error expanding subscription');
    }
  };

  const handleBan = async (keyId) => {
    try {
      await axios.get(`https://director.ankumo.ru/web/api/ban`, {
        params: { toBan: keyId, user: userInfo.username },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      toast.success(`Banned ${keyId}`);
      fetchKeys();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : 'Error');
    }
  };

  const handleChangeParam = (event) => {
    setSelectedParam(event.target.value);
  };
  
  return (
    <div className="keys-page">
      <div className="param-select-container">
        <select
          className="param-select"
          value={selectedParam}
          onChange={handleChangeParam}>
          <option value="unused">Keys</option>
          <option value="users">Users</option>
          <option value="expired">Expired</option>
        </select>
      </div>
      <div className="keys-container">
        {keys.map((keyData) => (
          <KeyContainer
            key={keyData.key}
            keyData={keyData}
            onDelete={handleDeleteKey}
            onSwitchBuild={handleSwitchBuild}
            onExpand={handleExpandSubscription}
            onBan={handleBan}
            isAdmin={isAdmin}
            selectedParam={selectedParam}
          />
        ))}
        <span className="focus-bg"></span>
      </div>
    </div>
  );
};

export default Keys;
