import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './status.css';

const ServerStatus = () => {
  const [status, setStatus] = useState('checking');

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const response = await axios.get('https://director.ankumo.ru/web/status');
        if (response.data.status === 'ok') {
          setStatus('online');
        } else {
          setStatus('offline');
        }
      } catch (error) {
        setStatus('offline');
      }
    };

    checkServerStatus();

    const intervalId = setInterval(checkServerStatus, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="status-container">
      <span className="status-text">Status:</span>
      <div className={`status-indicator ${status}`}></div>
    </div>
  );
};

export default ServerStatus;